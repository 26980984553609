<template>
  <div class="center divider-section">
    <h1 class="title">{{ dividerText }}</h1>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: 'SectionDivider',
  props: {
    dividerText: String,
  }
}
</script>

<style lang="scss">
.divider-section {
  display: flex;
  align-items: center;
  margin: 20px 0 35px 0;
  .title {
    flex: 0.5;
    font-size: 20px;
    white-space: nowrap;
    color: var(--primary-color);
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
  .divider {
    flex: 9.5;
    height: 2px;
    background-color: var(--primary-color);
    position: relative;
  }
}
</style>